import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axiosClient from '../components/AxiosClient';
import noteContext from '../context/notes/noteContext';
import { useNavigate } from 'react-router-dom';

const Coupons = () => {
    const [couponsData, setCouponsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterUsed, setFilterUsed] = useState('None'); // Default to "All"
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const { checkTokenExpiry } = useContext(noteContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCoupons = async () => {
            setIsLoading(true);
            const userToken = localStorage.getItem('token');
            if (!userToken) {
                setIsLoggedIn(false);
                setIsLoading(false);
                return;
            }

            try {
                checkTokenExpiry();
                const formData = new FormData();
                formData.append('used', filterUsed);
                formData.append('order', 'ascending');

                const response = await axiosClient.post(
                    '/coupons/get_coupons_user',
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                //console.log('Coupons retrieved successfully');
                const couponsWithStatus = response.data.data[0].map(coupon => ({
                    ...coupon,
                    status: getCouponStatus(coupon.created_at),
                }));
                setCouponsData(couponsWithStatus);
                //console.log(couponsWithStatus);
            } catch (error) {
                console.error('Error fetching coupons:', error);
            }
            setIsLoading(false);
        };

        fetchCoupons();
    }, [filterUsed]);

    const getCouponStatus = (createdAt) => {
        const createdTime = new Date(createdAt);
        const currentTime = new Date();
        const expirationTime = new Date(createdTime.getTime() + 48 * 60 * 60 * 1000);

        return currentTime > expirationTime ? 'Expired' : 'Active';
    };

    const handleFilterChange = (e) => {
        setFilterUsed(e.target.value);
    };

    const handleViewDetails = (coupon) => {
        setSelectedCoupon(coupon);
    };

    const handleClosePopup = () => {
        setSelectedCoupon(null);
    };

    const handleLoginRedirect = () => {
        navigate('/signin');
    };

    return (
        <>
            <Navbar />
            <div className="container mx-auto my-8 px-4">
                {isLoggedIn ? (
                    <>
                        <div className="flex justify-center sm:justify-between mb-4">
                            <select
                                value={filterUsed}
                                onChange={handleFilterChange}
                                className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            >
                                <option value="None">All</option>
                                <option value="True">Used</option>
                                <option value="False">Not Used</option>
                            </select>
                        </div>
                        <h1 className="text-3xl font-bold my-8 text-center sm:text-left">
                            Coupons
                        </h1>
                        {isLoading ? (
                            <p className="text-center">Loading...</p>
                        ) : (
                            <div>
                                {couponsData.length === 0 ? (
                                    <p className="text-gray-500 text-center">No coupons available.</p>
                                ) : (
                                    <table className="min-w-full border border-gray-300">
                                        <thead>
                                            <tr className="bg-gray-200 text-gray-700">
                                                <th className="px-4 py-2 border">Medicine Name</th>
                                                <th className="px-4 py-2 border">Status</th>
                                                <th className="px-4 py-2 border">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {couponsData.map((coupon, index) => (
                                                <tr key={index} className="border-b">
                                                    <td className="px-4 py-2 border break-words max-w-xs">
                                                        {coupon.product_name}
                                                    </td>
                                                    <td
                                                        className={`px-4 py-2 border ${
                                                            coupon.status === 'Expired'
                                                                ? 'text-red-500'
                                                                : 'text-green-500'
                                                        }`}
                                                    >
                                                        {coupon.status}
                                                    </td>
                                                    <td className="px-4 py-2 border text-center">
                                                        <button
                                                            onClick={() => handleViewDetails(coupon)}
                                                            className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-yellow-500"
                                                        >
                                                            View
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center bg-gradient-to-r from-orange-400 to-yellow-400 text-white p-6 rounded-lg shadow-md w-10/12 sm:w-1/3 max-w-md mx-auto">
                        <p className="text-center text-xl font-semibold mb-4">
                            Please login to see your coupons.
                        </p>
                        <button
                            onClick={handleLoginRedirect}
                            className="bg-white text-black px-6 py-2 rounded-md focus:outline-none hover:bg-white hover:scale-105 transition-all duration-300"
                        >
                            Login
                        </button>
                    </div>
                )}
            </div>
            {selectedCoupon && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
                    <div className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white p-6 rounded-lg shadow-md w-10/12 sm:w-1/3 max-w-md">
                        <div className="mb-4">
                            <h2 className="text-xl font-bold">Coupon Details</h2>
                        </div>
                        <div>
                            {/* <p>User Name: {selectedCoupon.user_name}</p> */}
                            <p>Vendor Phone Number: {selectedCoupon.vendor_phone}</p>
                            <p>Product Name: {selectedCoupon.product_name}</p>
                            <p>Pharmacy Name: {selectedCoupon.pharmacy_name}</p>
                            <p>Discount: {selectedCoupon.discount}</p>
                            <p>Discounted Price: {selectedCoupon.discounted_price}</p>
                            <p>MRP: {selectedCoupon.mrp}</p>
                            <p>Created At: {selectedCoupon.created_at}</p>
                            <p>Expiry: {selectedCoupon.expiry}</p>
                            <p>Status: {selectedCoupon.is_used ? "Used" : "Not used"    }</p>
                            <p>Quantity: {selectedCoupon.quantity}</p>
                        </div>
                        <div className="mt-4 text-right">
                            <button
                                onClick={handleClosePopup}
                                className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md focus:outline-none hover:from-orange-500 hover:to-yellow-500 transition-all duration-600"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};

export default Coupons;
